<template>
  <div>
    <div class="classDetail-center clear">
      <div class="classDetail-tz">
        <span class="el-icon-message-solid">&nbsp{{notice}}</span>
      </div>
      <div class="class_detail-list">
        <div class="to-lead dis-flex" @click="lead">
          <div class="to-lead-img">
            <img src="../../assets/images/daochu.png" alt=""></div>
          <div class="to-lead-btn">
            <el-button
                style="height: 32px;line-height: 6px;
             color: #FFFFFF;border: 0px;font-size: 16px;
             background-color:#FF8E42">导入班级成员
            </el-button>
          </div>
        </div>
        <div class="class-list">
          <div class="to-lead-item ">
            <el-checkbox-group
                v-model="checkedCities"
                @change="handleCheckedCitiesChange">
              <el-checkbox
                  style="position: relative;background: #F8F8F8;margin-top: 20px;
                width: 200px;height: 70px;line-height: 70px;padding-left: 10px"
                  v-for="(city,index) in cities"
                  :label="city.id"
                  :key="index">
                <div class="checkbox-items dis-flex">
                  <div class="checkbox-item-img">
                    <img :src="city.avatar_url" alt="">
                  </div>
                  <p>{{ city.name }}</p>
                </div>
              </el-checkbox>
            </el-checkbox-group>
            <!--            全选-->
            <div class="dis-flex margin-auto-20">
              <el-checkbox
                  style="padding-left: 10px;height: 32px;
                   line-height: 32px;"
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange">全选
              </el-checkbox>
              <div class="del cursor-pointer" @click="dell">删除</div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import api from '@/service/api/api'
export default {
  name: "classDetail",
  data() {
    return {
      name:'',
      notice:'',
      checkAll: false,
      isIndeterminate: false,
      checkedCities: [],
      cities: [],
      // 全选数组
      checkedGameId:[],
      studentdemo:'',
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 初始化
    init() {
      let params = {
        class_id:this.$route.query.id
      }
      api.classDetail(params).then(res=>{
        if (res.errcode === 0) {
          this.cities = res.data.memberinfo
          this.notice = res.data.notice
          this.name = res.data.name
          this.studentdemo= res.data.studentdemo
          for (let i = 0; i < this.cities.length; i++) {
            this.checkedGameId.push(this.cities[i].id);
          }
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },
    // 导入
    lead() {
      console.log(this.name)
    this.$router.push({
      path:'/tolead',
      query:{
        name:this.name,
        id:this.$route.query.id,
          studentdemo:this.studentdemo
      }
    })
    },
    handleCheckAllChange(val) {
      console.log(this.checkedGameId)
      this.checkedCities = val ? this.checkedGameId : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(value,this.checkedCities)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;

    },
    // 删除
    dell() {
      console.log(this.checkedCities)
      let params = {
        classmember_id:this.checkedCities.join(',')
      }
      console.log(params)
       api.delclassDetail(params).then(res=>{
         if (res.errcode === 0) {
           this.init()
           this.$message({
             message: res.errmsg,
             duration: 2000,
             type: "warning"
           });
         } else {
           this.$message({
             message: res.errmsg,
             duration: 2000,
             type: "warning"
           });
         }
       })
    },
  }
}
</script>

<style scoped>
.classDetail-center {
  width: 1200px;
  margin: 20px auto;
  min-height: 800px;
  padding: 10px 0;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 8px;
}

.classDetail-tz {
  width: 98%;
  height: 44px;
  line-height: 44px;
  padding-left: 10px;
  background: #E9F0FF;
  margin: 20px auto;
  color: #FF8E42;
  font-size: 16px;
}

.class_detail-list {
  width: 98%;
  min-height: 200px;
  margin: 0 auto;
}

.to-lead {
  width: 150px;
  height: 32px;
  background: #FF8E42;
  border-radius: 8px;
}

.to-lead-img {
  width: 22px;
  height: 17px;
  margin: 6px 6px 0 10px;
}

.to-lead-img > img {
  width: 100%;
  height: 100%;
}

.to-lead-btn {
  width: 100px;
}

.class-list {
  width: 100%;
  min-height: 200px;
}

.checkbox-items {
  width: 200px;
  height: 70px;
  /*margin-top: 20px;*/
  position: absolute;
  left: 50px;
  top: 0;
}

.checkbox-item-img {
  width: 44px;
  height: 44px;
  margin-top: 13px;
  border-radius: 4px;
}

.checkbox-item-img > img {
  width: 100%;
  height: 100%;
  border-radius: 4px;

}

.checkbox-items p {
  width: 100px;
  height: 70px;
  line-height: 70px;
  padding-left: 10px;
}

.del {
  width: 80px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  background: #FF8E42;
  border-radius: 8px;
  margin-left: 10px;
  margin-top: 2px;
}
</style>